import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import moment from "moment";

// Define the thunk for fetching log data
export const getAllLogsData = createAsyncThunk(
  "logs/getAllLogsData",
  async () => {
    try {
      const token = localStorage.getItem("token");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      // Replace with your actual API endpoint
      const response = await axios.get(
        `${process.env.REACT_APP_BASEURL}/sessionLogs`,
        config
      );
      return response.data; // Assuming the API returns the data in the response body
    } catch (error) {
      // Handle errors as needed
      throw new Error(
        error.response ? error.response.data.message : error.message
      );
    }
  }
);

export const getUserLogsList = createAsyncThunk(
  "logs/getUserLogsList",
  async ({ userId, startDate, endDate }) => {
    try {
      const endDateFormate = moment(endDate).endOf("day").toISOString();
      const token = localStorage.getItem("token");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          startDate: new Date(startDate),
          endDate: new Date(endDateFormate),
        }, // Passing query parameters
      };

      const response = await axios.get(
        `${process.env.REACT_APP_BASEURL}/log/${userId}`,
        config
      );
      return response.data; // Assuming the API returns the data in the response body
    } catch (error) {
      // Handle errors as needed
      throw new Error(
        error.response ? error.response.data.message : error.message
      );
    }
  }
);

export const getLogsData = createAsyncThunk(
  "logs/getLogsData",
  async (
    { sort, sortColumn, q, page, perPage, actions, userName },
    thunkAPI
  ) => {
    // Get all data using the first thunk
    let allData = await thunkAPI.dispatch(getAllLogsData());
    allData = allData.payload;

    // Filter based on the provided criteria
    const filteredData = allData.filter((user) => {
      return (
        (!q ||
          user?.userId?.firstName.toLowerCase().includes(q.toLowerCase()) ||
          user?.userId?.lastName.toLowerCase().includes(q.toLowerCase())) &&
        (!actions || user?.action === actions) &&
        (!userName || user?.userId?._id === userName)
      );
    });

    // Sort the filtered data
    if (sortColumn && sort) {
      filteredData.sort((a, b) => {
        if (sort === "asc") {
          return a[sortColumn] > b[sortColumn] ? 1 : -1;
        } else {
          return a[sortColumn] < b[sortColumn] ? 1 : -1;
        }
      });
    }

    // Paginate the sorted data
    const startIndex = (page - 1) * perPage;
    const paginatedData = filteredData.slice(startIndex, startIndex + perPage);

    return {
      params: {
        sort,
        sortColumn,
        q,
        page,
        perPage,
      },
      data: paginatedData,
      totalPages: Math.ceil(filteredData.length / perPage),
    };
  }
);

export const getUserLogsData = createAsyncThunk(
  "logs/getUserLogsData",
  async (
    {
      sort,
      sortColumn,
      q,
      page,
      perPage,
      actions,
      userName,
      userId,
      startDate,
      endDate,
    },
    thunkAPI
  ) => {
    // Get all data using the first thunk
    let allData = await thunkAPI.dispatch(
      getUserLogsList({ userId, startDate, endDate })
    );
    allData = allData.payload;

    // Filter based on the provided criteria
    const filteredData = allData?.data.filter((user) => {
      return (
        (!q ||
          allData?.user?.firstName.toLowerCase().includes(q.toLowerCase()) ||
          allData?.user?.lastName.toLowerCase().includes(q.toLowerCase())) &&
        (!actions || user?.page?.slice(1) === actions) &&
        user?.page !== "/workspace"
      );
    });

    // Sort the filtered data
    if (sortColumn && sort) {
      filteredData.sort((a, b) => {
        if (sort === "asc") {
          return a[sortColumn] > b[sortColumn] ? 1 : -1;
        } else {
          return a[sortColumn] < b[sortColumn] ? 1 : -1;
        }
      });
    }

    // Paginate the sorted data
    const startIndex = (page - 1) * perPage;
    const paginatedData = filteredData.slice(startIndex, startIndex + perPage);

    return {
      params: {
        sort,
        sortColumn,
        q,
        page,
        perPage,
      },
      data: paginatedData,
      totalPages: Math.ceil(filteredData.length / perPage),
    };
  }
);

// Create the slice
export const logsSlice = createSlice({
  name: "logs",
  initialState: {
    allLogs: [],
    logs: [],
    status: "idle",
    userAllLogs: [],
    userLogs: [],
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllLogsData.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.allLogs = action.payload; // Update state with the fetched logs
      })
      .addCase(getUserLogsList.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.userAllLogs = action.payload; // Update state with the fetched logs
      })
      .addCase(getLogsData.fulfilled, (state, action) => {
        state.logs = action.payload.data;
        state.params = action.payload.params;
        state.total = action.payload.totalPages;
      })
      .addCase(getUserLogsData.fulfilled, (state, action) => {
        state.userLogs = action.payload.data;
        state.params = action.payload.params;
        state.total = action.payload.totalPages;
      });
  },
});

export default logsSlice.reducer;
