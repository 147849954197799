// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// ** Axios Imports
import axios from "axios";

export const getAllData = createAsyncThunk("appUsers/getAllData", async () => {
  const token = localStorage.getItem("token");
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BASEURL}/web_users`,
      config
    );
    return response.data.users;
  } catch (error) {
    // Handle errors
    console.error("Error fetching user data:", error);
    throw error;
  }
});

export const getRequestedDemoAllData = createAsyncThunk(
  "appUsers/getRequestedDemoAllData",
  async () => {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASEURL}/requested_demo_users`,
        config
      );
      console.log({ response });
      return response.data.users;
    } catch (error) {
      // Handle errors
      console.error("Error fetching user data:", error);
      throw error;
    }
  }
);

// export const getData = createAsyncThunk('appUsers/getData', async params => {

//   const response = await axios.get('/api/users/list/data', params)
//   return {
//     params,
//     data: response.data.users,
//     totalPages: response.data.total
//   }
// })

export const getData = createAsyncThunk(
  "appUsers/getData",
  async (
    { sort, sortColumn, q, page, perPage, role, status, activeTab },
    thunkAPI
  ) => {
    // Get all data using the first thunk
    let allData = await thunkAPI.dispatch(getAllData());
    allData = allData.payload;

    // Filter based on the provided criteria
    const filteredData = allData.filter((user) => {
      return (
        (!activeTab || activeTab === "1"
          ? user.status !== "blocked"
          : user.status === "blocked") &&
        (!role || user.role === role) &&
        (!status || user.status === status) &&
        (!q ||
          user.firstName.toLowerCase().includes(q.toLowerCase()) ||
          user.lastName.toLowerCase().includes(q.toLowerCase()))
      );
    });

    // Sort the filtered data
    if (sortColumn && sort) {
      filteredData.sort((a, b) => {
        if (sort === "asc") {
          return a[sortColumn] > b[sortColumn] ? 1 : -1;
        } else {
          return a[sortColumn] < b[sortColumn] ? 1 : -1;
        }
      });
    }

    // Paginate the sorted data
    const startIndex = (page - 1) * perPage;
    const paginatedData = filteredData.slice(startIndex, startIndex + perPage);

    return {
      params: {
        sort,
        sortColumn,
        q,
        page,
        perPage,
        role,
        status,
        activeTab,
      },
      data: paginatedData,
      totalPages: Math.ceil(filteredData.length / perPage),
    };
  }
);

export const getRequestedDemoData = createAsyncThunk(
  "appUsers/getRequestedDemoData",
  async ({ sort, sortColumn, q, page, perPage }, thunkAPI) => {
    // Get all data using the first thunk
    let allData = await thunkAPI.dispatch(getRequestedDemoAllData());
    allData = allData.payload;

    // Filter based on the provided criteria
    const filteredData = allData.filter((user) => {
      return (
        !q ||
        user.firstName.toLowerCase().includes(q.toLowerCase()) ||
        user.lastName.toLowerCase().includes(q.toLowerCase())
      );
    });

    // Sort the filtered data
    if (sortColumn && sort) {
      filteredData.sort((a, b) => {
        if (sort === "asc") {
          return a[sortColumn] > b[sortColumn] ? 1 : -1;
        } else {
          return a[sortColumn] < b[sortColumn] ? 1 : -1;
        }
      });
    }

    // Paginate the sorted data
    const startIndex = (page - 1) * perPage;
    const paginatedData = filteredData.slice(startIndex, startIndex + perPage);

    return {
      params: {
        sort,
        sortColumn,
        q,
        page,
        perPage,
      },
      data: paginatedData,
      totalPages: Math.ceil(filteredData.length / perPage),
    };
  }
);

export const getUser = createAsyncThunk("appUsers/getUser", async (id) => {
  const response = await axios.get("/api/users/user", { id });
  return response.data.user;
});

export const addUser = createAsyncThunk(
  "appUsers/addUser",
  async (user, { dispatch, getState }) => {
    await axios.post("/apps/users/add-user", user);
    await dispatch(getData(getState().users.params));
    await dispatch(getAllData());
    return user;
  }
);

export const deleteUser = createAsyncThunk(
  "appUsers/deleteUser",
  async (id, { dispatch, getState }) => {
    await axios.delete("/apps/users/delete", { id });
    await dispatch(getData(getState().users.params));
    await dispatch(getAllData());
    return id;
  }
);

export const appUsersSlice = createSlice({
  name: "appUsers",
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    requestedDemoallData: [],
    requestedData: [],
    selectedUser: null,
  },
  reducers: {
    updateBlockList: (state, action) => {
      //Updating all data
      const dataFliter = state.allData.findIndex(
        (i) => i._id === action.payload?.id
      );
      if (dataFliter !== -1) {
        const tempData = state.allData;
        tempData[dataFliter] = {
          ...tempData[dataFliter],
          status: action.payload?.type,
        };
        state.allData = tempData;
      }

      //Updating  data
      const dataIndex = state.data.findIndex(
        (i) => i._id === action.payload?.id
      );
      if (dataIndex !== -1) {
        const tempData = state.data;
        tempData[dataIndex] = {
          ...tempData[dataIndex],
          status: action.payload?.type,
        };
        state.data = tempData;
      }
    },
    updatedUserList: (state, action) => {
      const userIdToDelete = action.payload?.id;

      // Remove the user from allData array
      state.allData = state.allData.filter(
        (user) => user._id !== userIdToDelete
      );

      // Remove the user from data array
      state.data = state.data.filter((user) => user._id !== userIdToDelete);

      //Remove the user from requestdata user array
      state.requestedDemoallData = state.requestedDemoallData.filter(
        (user) => user._id !== userIdToDelete
      );
      state.requestedData = state.requestedData.filter(
        (user) => user._id !== userIdToDelete
      );
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload;
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.params = action.payload.params;
        state.total = action.payload.totalPages;
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.selectedUser = action.payload;
      })
      .addCase(getRequestedDemoAllData.fulfilled, (state, action) => {
        state.requestedDemoallData = action.payload;
      })
      .addCase(getRequestedDemoData.fulfilled, (state, action) => {
        state.requestedData = action.payload.data;
        state.params = action.payload.params;
        state.total = action.payload.totalPages;
      });
  },
});

export const { updateBlockList, updatedUserList } = appUsersSlice.actions;

export default appUsersSlice.reducer;
